import Layout from "./common/layout"
import Head from "./common/Head"
import Performance from "./product/Performance/Performance"
import { useFormatter } from "helpers/i18n"

const ProductPerformance = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Power performance dashboard to magnify e-commerce impact"
            : "Hệ thống báo cáo hoạt động kinh doanh tích hợp, hiệu quả"
        }
        description={
          currentLocale === "en"
            ? "Measure and magnify your impact with OnPoint power performance dashboard."
            : "Đo lường và kiểm soát hiệu quả hoạt động với hệ thống báo cáo hoạt động kinh doanh của OnPoint."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/product-4-meta.png"
        }
      />
      <Layout>
        <Performance />
      </Layout>
    </>
  )
}

export default ProductPerformance
